import React from 'react';
import { Link } from 'react-router-dom';

const projects = [
    { name: 'Pirojpur', path: '/pirojpur', hasInner: true },
    { name: 'Meherpur', path: '/meherpur', hasInner: false },
];

function ProjectsHome() {
    return (
        <div className='projects-container'>
            <h1>Projects List</h1>
            <div className="project-cards">
                {projects.map((project, index) => (
                    <div key={index} className="card">
                        <Link to={project.path}>
                            <h2>{project.name}</h2>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ProjectsHome;
