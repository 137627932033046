// DynamicTable.js
import React from 'react';

const DynamicTable = ({ headers, data }) => {
    return (
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
                <tr style={{ backgroundColor: '#f2f2f2' }}>
                    {headers.map((header, index) => (
                        <th key={index} style={{ border: '1px solid #dddddd', padding: '8px' }}>
                            {header}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((row, rowIndex) => (
                    <tr key={rowIndex} style={{ borderBottom: '1px solid #dddddd' }}>
                        {row.map((cell, cellIndex) => (
                            <td key={cellIndex} style={{ padding: '8px'}}>
                                {cell}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default DynamicTable;
