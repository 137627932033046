import Table from './table';

// Drone Survey Calculation with JSX return
export const calculateZoneSurvey = (features) => {
    let totalCount = 0;
    let totalAt = 0;
    let totalDrawing = 0;

    features.forEach((feature) => {
        const At = feature.properties.AT
        const Drawing = feature.properties.Drawing;
        totalCount++;

        if (At === 'Yes') {
            totalAt++;
        }
        if (Drawing === 'Yes') {
            totalDrawing++;
        }
    });
    const headers = ['Type', 'Count'];

    const data = [
        ['AT Completed', totalAt],
        ['Drawing Completed', totalDrawing]
    ];
    return (
        <div>
            <h3 style={{ 'text-align': 'center' }}>Progress</h3>
            <h5 style={{ 'text-align': 'center' }}>Total Zone: {totalCount}</h5>
            <Table headers={headers} data={data} />

        </div>
    );
};

// PF Survey Calculation with JSX return
export const calculateStructureDrawing = (features) => {
    // Initialize counts for today and the last five days
    const counts = {};

    // Days of the week array
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    // Get the current date and the previous five days
    const currentDate = new Date();

    // Loop through the last 6 days (including today)
    for (let i = 0; i < 5; i++) {
        const date = new Date(currentDate);
        date.setDate(date.getDate() - i);

        // Format the date to dd/mm
        const formattedDate = `${String(date.getDate()).padStart(2, '0')}/${String(date.getMonth() + 1).padStart(2, '0')}`;

        // Get the day of the week
        const dayOfWeek = daysOfWeek[date.getDay()];

        // Store both date and day in counts
        counts[`${dayOfWeek} (${formattedDate})`] = 0;
    }

    // Count features based on their Create_Date
    features.forEach((feature) => {
        const createDate = new Date(feature.properties.Create_Date);
        createDate.setHours(0, 0, 0, 0); // Normalize time for comparison

        // Format the createDate to dd/mm
        const formattedCreateDate = `${String(createDate.getDate()).padStart(2, '0')}/${String(createDate.getMonth() + 1).padStart(2, '0')}`;

        // Get the day of the week for createDate
        const createDayOfWeek = daysOfWeek[createDate.getDay()];

        // Increment the count for the formatted date if it exists in the counts object
        const dateKey = `${createDayOfWeek} (${formattedCreateDate})`;
        if (counts[dateKey] !== undefined) {
            counts[dateKey]++;
        }
    });

    // Prepare data for the DynamicTable
    const headers = ['Date', 'Structures'];
    const data = Object.keys(counts).map((date) => [date, counts[date]]);

    return (
        <div>
            <h3 style={{ 'text-align': 'center' }}>Progress</h3>
            <h5 style={{ 'text-align': 'center' }}>Total Structure: {features.length}</h5>

            <Table headers={headers} data={data} />
        </div>
    );
};

export const calculateWaterbodyDrwaing = (features) => {
    let totalCount = 0;


    features.forEach((feature) => {
        const progress = feature.properties.Progress;
        totalCount++;
    });

    return (
        <div>
             <h3 style={{ 'text-align': 'center' }}>Progress</h3>
             <h5 style={{ 'text-align': 'center' }}>Total Waterbody: {totalCount}</h5>
        </div>
    );
};
