import React from 'react'
import WMSMap from './components/mainMap';
import ResultsIndex from './components/progressIndex';
import './utils/project.css';

export default function meherpurIndex() {
    return (
        <div>
            <div className='maps'>
                <WMSMap />
            </div>

            <ResultsIndex />

        </div>
    )
}
