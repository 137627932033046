// components/TabButton.js

import React from 'react';

const TabButton = ({ isActive, label, onClick }) => {
    const buttonClass = isActive ? 'tab-button active' : 'tab-button';

    return (
        <button className={buttonClass} onClick={onClick}>
            {label}
        </button>
    );
};

export default TabButton;
