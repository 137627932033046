import React from 'react';

function Rangpur() {
    return (
        <div>
            <h1>SRDP - Rangpur Project</h1>
            <p>This is the SRDP Rangpur project details.</p>
        </div>
    );
}

export default Rangpur;
