import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import ProjectsHome from './Projects/ProjectsHome/home'; // Home page that lists all main projects
import Pirojpur from './Projects/Pirojpur'; // SRDP main component
import Shylhet from './Projects/SRDP/Shylhet'; // SRDP Shylhet component
import Rangpur from './Projects/SRDP/Rangpur'; // SRDP Rangpur component
import Meherpur from './Projects/Meherpur'; // Meherpur project component

import './App.css'

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ProjectsHome />} />
        <Route path="/pirojpur" element={<Pirojpur />} />
        {/* <Route path="/srdp/shylhet" element={<Shylhet />} /> 
        <Route path="/srdp/rangpur" element={<Rangpur />} /> */}
        <Route path="/meherpur" element={<Meherpur />} /> {/* Meherpur */}
      </Routes>
    </Router>
  );
}

export default App;
