import React, { useState, useEffect } from 'react';
import { fetchWFSData } from '../utils/wfsDataFetcher';
import { calculateStructureDrawing, calculateWaterbodyDrwaing, calculateZoneSurvey } from '../utils/progressCalculation';
import TabButton from './tabButton';
import Icon from '../utils/rising.png';

const geoserverURL = process.env.REACT_APP_GEOSERVER_URL;

const baseWfsURL = (server, layer) =>
    `${geoserverURL}/${server}/wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=${server}:${layer}&outputFormat=application/json`;

const ResultsIndex = () => {
    const [features, setFeatures] = useState([]);
    const [wfsUrl, setWfsUrl] = useState(baseWfsURL('GIS-Server-Pirojpur', 'STRUCTURE'));
    const [layerType, setLayerType] = useState('STRUCTURE');
    const [activeTab, setActiveTab] = useState();
    const [calculatedResults, setCalculatedResults] = useState(null);
    const [loading, setLoading] = useState(false);
    const [shouldFetch, setShouldFetch] = useState(false);
    const [panelVisible, setPanelVisible] = useState(false); // State to control panel visibility

    useEffect(() => {
        if (shouldFetch) {
            const fetchData = async () => {
                setLoading(true);
                const fetchedFeatures = await fetchWFSData(wfsUrl);
                handleDataFetched(fetchedFeatures);
            };

            fetchData();
            setShouldFetch(false);
        }
    }, [shouldFetch, wfsUrl]);

    const handleDataFetched = (fetchedFeatures) => {
        setFeatures(fetchedFeatures);
        setLoading(false);
        calculateResults(fetchedFeatures, layerType);
    };

    const handleTabChange = (newUrl, type) => {
        setWfsUrl(newUrl);
        setLayerType(type);
        setActiveTab(type);
        setFeatures([]);
        setCalculatedResults(null);
        setShouldFetch(true);
    };

    const calculateResults = (features, type) => {
        if (type === 'STRUCTURE') {
            const results = calculateStructureDrawing(features);
            setCalculatedResults(results);
        } else if (type === 'Waterbody') {
            const results = calculateWaterbodyDrwaing(features);
            setCalculatedResults(results);
        } else if (type === 'Zone') {
            const results = calculateZoneSurvey(features);
            setCalculatedResults(results);
        }
    };

    const togglePanelVisibility = () => {
        setPanelVisible(!panelVisible); // Toggle panel visibility
        setFeatures([]);
        setCalculatedResults(null);
        setActiveTab(null);
    };

    return (
        <div>
            {/* Floating button to toggle the panel */}
            {!panelVisible && (
                <button className="floating-button" onClick={togglePanelVisibility}>
                    <img src={Icon} alt="Rising Icon"
                        style={{ width: '24px', height: '24px' }} />
                </button>
            )}

            {/* Results Panel */}
            {panelVisible && (
                <div className="results-panel">
                    {/* Close button inside the panel */}
                    <button className="close-button" onClick={togglePanelVisibility}>
                        ✖
                    </button>

                    <div className="results-block">
                        <div className="tab-buttons">
                            <TabButton
                                isActive={activeTab === 'STRUCTURE'}
                                label="Structure"
                                onClick={() =>
                                    handleTabChange(
                                        baseWfsURL('GIS-Server-Pirojpur', 'STRUCTURE'),
                                        'STRUCTURE'
                                    )
                                }
                            />
                            <TabButton
                                isActive={activeTab === 'Waterbody'}
                                label="Waterbody"
                                onClick={() =>
                                    handleTabChange(
                                        baseWfsURL('GIS-Server-Pirojpur', 'WATERBODY_POLYGON'),
                                        'Waterbody'
                                    )
                                }
                            />

                            <TabButton
                                isActive={activeTab === 'Zone'}
                                label="Survey Zone"
                                onClick={() =>
                                    handleTabChange(
                                        baseWfsURL('GIS-Server-Pirojpur', 'SURVEY_ZONE'),
                                        'Zone'
                                    )
                                }
                            />
                        </div>

                        {loading && <div className="loader">Loading...</div>}

                        {!loading && calculatedResults && calculatedResults}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ResultsIndex;
