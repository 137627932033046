import React, { useEffect, useState } from 'react';
import 'ol/ol.css';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import { fromLonLat } from 'ol/proj';
import OSM from 'ol/source/OSM';
import TileWMS from 'ol/source/TileWMS';
import LayerGroup from "ol/layer/Group";
import "ol-layerswitcher/src/ol-layerswitcher.css";
import LayerSwitcher from "ol-layerswitcher";
import SatelliteMap from "ol/source/XYZ";
import XYZ from "ol/source/XYZ";
import { defaults as defaultControls } from 'ol/control';



const geoserverURL = process.env.REACT_APP_GEOSERVER_URL;

const GoogleMap = new TileLayer({
  title: "Google",
  type: "base",
  source: new XYZ({
    url: "http://mt0.google.com/vt/lyrs=p&hl=en&x={x}&y={y}&z={z}",
  }),
  visible: false,
});
const SatelliteMapp = new TileLayer({
  title: "Satellite",
  type: "base",

  source: new SatelliteMap({
    url: "https://services.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
    maxZoom: 23,
  }),
  visible: false,
});
const OsmBase = new TileLayer({
  source: new OSM(),
  title: "OSM",
  type: "base",
});

const createLayer = (title, layerName, visible, opacity) => {
  return (
    new TileLayer({
      title: title,
      source: new TileWMS({
        url: `${geoserverURL}/GIS-Server-Pirojpur/wms`,
        params: {
          LAYERS: `GIS-Server-Pirojpur:${layerName}`,
          TILED: true,
        },
        serverType: 'geoserver',
      }),
      visible: visible,
      opacity: opacity || 1
    })
  )
}

// Survey Data Layer
const rodaLayer = createLayer('Road DL', 'ROAD_DL', true);
const waterbodyPolyLayer = createLayer('Waterbody Poly', 'WATERBODY_POLYGON', true);
const waterbodyLineLayer = createLayer('Waterbody Line', 'WATERBODY_LINE', true);
const structureLayer = createLayer('STRUCTURE', 'STRUCTURE', true);

// Survey Plan Layer
const SurveyBlockLayer = createLayer('Survey Block', 'SURVEY_BLOCK', false);
const SurveyGridLayer = createLayer('Survey Grid', 'SURVEY_GRID', false);
const SurveyZoneLayer = createLayer('Survey Zone', 'SURVEY_ZONE', true, 0.4);


const WMSMap = () => {
  const [map, setMap] = useState();



  useEffect(() => {

    const initialZoom = window.innerWidth < 1000 ? 14 : 13.5;

    const map = new Map({
      target: 'map', // The div id for OpenLayers map
      layers: [

        new LayerGroup({
          title: "Base Map",
          name: "Base",
          fold: "close",
          layers: [SatelliteMapp, OsmBase, GoogleMap],
        }),
        new LayerGroup({
          title: "Survey Plan",
          name: "Survey Plan",
          fold: "close",
          layers: [SurveyBlockLayer, SurveyGridLayer, SurveyZoneLayer
          ]
        }),
        new LayerGroup({
          title: "Survey Data",
          name: "Survey Data",
          fold: "close",
          layers: [rodaLayer, waterbodyLineLayer, waterbodyPolyLayer, structureLayer,
          ]
        }),

      ],
      view: new View({
        center: [89.98948948132236, 22.58739820525511], // Adjust coordinates 22.58739820525511, 89.97648948132236
        zoom: initialZoom,
        projection: 'EPSG:4326'
      }),
      controls: defaultControls({
        zoom: false, // Disable zoom controls (zoom in/out icons)
      }),
    });


    setMap(map);
    return () => map.setTarget(null); // Cleanup map on component unmount
  }, []);


  useEffect(() => {
    /* *********** Layer Switcher ********** */
    const layerSwitcher = new LayerSwitcher();
    if (map != null || map != undefined) {
      map.addControl(layerSwitcher);
    }
    /* *********** Overlay ********** */
  }, [map]);

  return <div id="map" style={{ width: '100%', height: '100vh' }}></div>;
};

export default WMSMap;
